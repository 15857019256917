import objectContent from '../objective/ContentStrand.vue';
import keyContent from '../objective/KeyExp.vue';
import learningContent from '../objective/LearningTrajectory.vue';
import OverviewContent from './Overview.vue';
export default {
  name: "ObjectIndex",
  components: {
    objectContent: objectContent,
    keyContent: keyContent,
    learningContent: learningContent,
    OverviewContent: OverviewContent
  },
  data: function data() {
    return {
      activeName: 'four',
      //four概览 third关键经验
      courseId: this.$route.query.courseId,
      courseName: this.$route.query.courseName || '',
      code: this.$route.query.code || '',
      stateCourse: parseInt(this.$route.query.stateCourse) || '',
      breadList: []
    };
  },
  watch: {},
  mounted: function mounted() {
    this.onQuery();
  },
  methods: {
    //手动设置navigation
    navigation: function navigation(navData) {
      this.$emit('navigation', navData);
    },
    handleClick: function handleClick(tab, event) {
      console.log("tab:", tab);
      this.$router.push({
        path: this.$route.path,
        query: {
          courseId: this.courseId,
          courseName: this.courseName,
          code: this.code,
          stateCourse: this.state
        }
      });
    },
    onQuery: function onQuery() {
      this.$refs[this.activeName].initDataFn();
    }
  }
};