import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import AddObject from '../../components/objective/AddObject.vue';
export default {
  name: "contentStrand",
  components: {
    AddObject: AddObject
  },
  data: function data() {
    return {
      defaultExpandAll: false,
      //是否默认展开所有节点
      centerDialogVisibleModule: false,
      //删除模块
      drawerModuleState: "add",
      //默认是添加模块
      baseType: 2,
      //上级节点类型：1目标概览、2目标领域、3关键经验、4教-学路径
      // 筛选
      filter: [{
        placeholder: '目标领域状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '目标领域名称',
        value: '',
        type: 'text',
        list: []
      }],
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 10,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: "rtl",
      drawerState: "",
      drawerData: {
        name: "",
        ename: "",
        rank: "",
        state: 1
      },
      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false,
      // 删除弹框显示隐藏
      // dialog弹框-复制弹框
      copyDialog: false,
      copyData: {
        rId: ''
      },
      courseList: [],
      localCourse: JSON.parse(sessionStorage.getItem("course-" + this.$route.query.courseId)),
      navData: [{
        //面包屑
        meta: {
          title: "课程"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "课程中心"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "课程管理"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "目标体系管理"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "目标领域"
        }
      }]
    };
  },
  mounted: function mounted() {
    //获取数据
    this.initDataFn();
  },
  methods: {
    // dialog弹框-删除弹框确定函数
    confirmModuleFun: function confirmModuleFun(type) {
      var _this = this;
      var requestName = "";
      var postData = {};
      //删除模块
      requestName = "deleteModuleContext";
      postData.id = this.delModuleId;
      postData.contextType = 1;
      postData.courseId = this.$route.query.courseId;
      postData.baseId = this.delModuleBaseId;
      //删除当前的资源类型
      this.service.axiosUtils({
        requestName: requestName,
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.$toast.success(res.message);
            _this.delId = "";
            _this.centerDialogVisible = false;
            _this.centerDialogVisibleModule = false;
            _this.initData();
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    //编辑/删除模块
    detailModule: function detailModule(type, val, baseVal) {
      var _this2 = this;
      console.log(type, val, baseVal);
      if (type === "edit") {
        this.drawerModuleState = "edit";
        // this.addModuleDialog = true;
        this.$refs.addObject.showAddModule();
        setTimeout(function () {
          _this2.moduleForm = {
            baseId: val.baseId,
            id: val.id,
            courseId: _this2.$route.query.courseId,
            baseType: _this2.baseType,
            cname: val.cname,
            ename: val.ename,
            content: val.content,
            contextType: val.contextType,
            //需要让后台传给我
            fileList: val.files,
            actList: val.actList,
            description: val.description,
            rank: val.rank,
            state: val.state
          };
          //设置弹框组件里面的数据
          _this2.$refs.addObject.setData("moduleForm", _this2.moduleForm);
        }, 30);
      } else if (type === "del") {
        this.centerDialogVisibleModule = true;
        this.delModuleId = val.id;
        this.delModuleBaseId = val.baseId;
      } else {}
    },
    //添加模块
    addModule: function addModule(row) {
      //显示添加模块的弹框
      this.drawerModuleState = "add";
      this.$refs.addObject.showAddModule();
      this.$refs.addObject.setModuleForm('baseId', row.id);
    },
    initDataFn: function initDataFn() {
      //设置面包屑
      this.$emit('navigation', this.navData);
      this.initData();
    },
    // ========================= 初始化函数 start=======================
    initCourse: function initCourse() {
      var _this3 = this;
      //getCourse
      this.service.axiosUtils({
        requestName: "getObjectTypeCourseAll",
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.courseList = res.data;
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    // 初始化表格数据
    initData: function initData() {
      var _this4 = this;
      this.service.axiosUtils({
        requestName: "getTargetareaList",
        data: {
          name: this.filter[1].value,
          state: this.filter[0].value,
          pageSize: this.pageSize,
          pageIndex: this.currentPage,
          courseId: this.localCourse.id
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this4.tableData = res.data.data;
            _this4.total = res.data.total;
          } else {
            _this4.$toast.error(res.message);
          }
        }
      });
    },
    //新建初始化数据
    clearDrawerData: function clearDrawerData() {
      this.drawerData = {
        name: "",
        ename: "",
        rank: "",
        state: 1
      };
      this.$refs.form.resetFields();
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    //筛选功能
    search: function search(filterArr) {
      var _this5 = this;
      this.service.axiosUtils({
        requestName: "getTargetareaList",
        data: {
          name: filterArr[1].value,
          state: filterArr[0].value,
          pageSize: this.pageSize,
          pageIndex: this.currentPage,
          courseId: this.localCourse.id
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this5.tableData = res.data.data;
            _this5.total = res.data.total;
          } else {
            _this5.$toast.error(res.message);
          }
        }
      });
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.initData();
    },
    //  =================== 添加/编辑 抽屉 相关函数  ===================
    // 添加函数
    addFun: function addFun() {
      this.drawer = true;
      this.drawerState = "add";
    },
    // 复制函数
    copyFun: function copyFun() {
      this.initCourse();
      this.copyDialog = true;
    },
    // table-中操作函数
    detail: function detail(type, val) {
      var _this6 = this;
      if (type === "edit") {
        this.drawerState = "edit";
        this.drawer = true;
        setTimeout(function () {
          _this6.drawerData = {
            name: val.name,
            ename: val.ename,
            rank: val.rank,
            state: val.state,
            id: val.id
          };
        }, 30);
      } else if (type === "del") {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else {}
    },
    // drawer抽屉-取消函数
    cancelClick: function cancelClick() {
      this.clearDrawerData();
      this.drawer = false;
    },
    // drawer抽屉-确定函数
    confirmClick: function confirmClick(formName) {
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = "";
          if (that.drawerState === "edit") {
            //修改
            url = "editTargetArea";
          } else {
            //添加
            url = "addTargetArea";
          }
          that.drawerData.courseId = that.localCourse.id;
          that.service.axiosUtils({
            requestName: url,
            data: that.drawerData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.clearDrawerData();
                if (that.drawerState === "add") {
                  that.currentPage = 1;
                }
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    //====================== table 操作函数 =======================
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this7 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "changeTargetareaState",
        data: {
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this7.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this7.$toast.error(res.message);
          }
        }
      });
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this8 = this;
      //删除当前的资源类型
      this.service.axiosUtils({
        requestName: "deleteTargetarea",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this8.$toast.success(res.message);
            _this8.delId = "";
            _this8.centerDialogVisible = false;
            _this8.initData();
          } else {
            _this8.$toast.error(res.message);
          }
        }
      });
    },
    // dialog弹框-复制目标领域确定函数
    copyConfirmFun: function copyConfirmFun() {
      var _this9 = this;
      this.service.axiosUtils({
        requestName: "copyTargetareaList",
        data: {
          "courseId": this.copyData.rId,
          "toCourseId": this.localCourse.id
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this9.$toast.success(res.message);
            //重置copyData的rId
            _this9.copyData.rId = "";
            _this9.copyDialog = false;
            _this9.initData();
          } else {
            _this9.$toast.error(res.message);
          }
        }
      });
    }
  }
};