import _objectSpread from "/Users/lianxiang/star/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import EditorU from '../../components/popup/EditorUeditor';
import AddObject from '../../components/objective/AddObject.vue';
export default {
  name: "LearningTrajectory",
  components: {
    AddObject: AddObject,
    EditorU: EditorU
  },
  data: function data() {
    return {
      defaultExpandAll: false,
      //是否默认全部展开
      centerDialogVisibleModule: false,
      //删除模块
      baseType: 4,
      //上级节点类型：1目标概览、2目标领域、3关键经验、4教-学路径
      drawerModuleState: "add",
      //默认是添加模块
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // 筛选
      filter: [{
        placeholder: '教-学路径状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '教-学路径名称',
        value: '',
        type: 'text',
        list: []
      }],
      //左边菜单是否折叠
      isCollapse: false,
      //树形结构数据
      treeData: [],
      originTreeData: [],
      //树形结构数据
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      currentTree: this.$route.query.tree ? JSON.parse(this.$route.query.tree) : {},
      nowCheckData: {},
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 10,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      drawerState: '',
      direction: "rtl",
      drawerArticleState: "",
      drawerData: {
        areaId: '',
        conceptId: '',
        title: "",
        ename: "",
        /* performance:'',//典型表现
         case:'', //案例展示
         guide:'',//指南
         activitys:[], //活动列表*/
        rank: "",
        state: 1
      },
      treeChangeList: [],
      keyChangeList: [],
      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false,
      // 删除弹框显示隐藏
      // 活动安排树的数据
      activityTreeData: [],
      defaultTree: {
        children: 'sublevel',
        label: 'name'
      },
      defaultExpandedArr: [],
      chooseActivityTree: [],
      chooseTreeTotal: 0,
      activityTreeState: false,
      // 编辑器的数据
      isEditorShow: false,
      ueidtorName: 'ueditor',
      val: '',
      modeType: 'descript',
      nowArticleData: '',
      itemName: '',
      // 编辑器返回drawData的字段
      // 当前添加/编辑所属的目标领域，关键经验
      nowAreaId: 0,
      nowAreaName: '',
      nowConceptId: 0,
      nowConceptName: '',
      localCourse: JSON.parse(sessionStorage.getItem("course-" + this.$route.query.courseId)),
      navData: [{
        //面包屑
        meta: {
          title: "课程"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "课程中心"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "课程管理"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "目标体系管理"
        },
        path: "/course/list"
      }, {
        meta: {
          title: "教-学路径"
        }
      }],
      courseList: [],
      formCourseData: {
        courseId: ''
      }
    };
  },
  watch: {
    '$route': 'changeRouter'
  },
  created: function created() {
    this.initDataFn();
  },
  methods: {
    // dialog弹框-删除弹框确定函数
    confirmModuleFun: function confirmModuleFun(type) {
      var _this = this;
      var requestName = "";
      var postData = {};
      //删除模块
      requestName = "deleteModuleContext";
      postData.id = this.delModuleId;
      postData.contextType = 1;
      postData.courseId = this.$route.query.courseId;
      postData.baseId = this.delModuleBaseId;
      //删除当前的资源类型
      this.service.axiosUtils({
        requestName: requestName,
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.$toast.success(res.message);
            _this.delId = "";
            _this.centerDialogVisible = false;
            _this.centerDialogVisibleModule = false;
            _this.initData();
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    //编辑/删除模块
    detailModule: function detailModule(type, val, baseVal) {
      var _this2 = this;
      console.log(type, val, baseVal);
      if (type === "edit") {
        this.drawerModuleState = "edit";
        // this.addModuleDialog = true;
        this.$refs.addObject.showAddModule();
        setTimeout(function () {
          _this2.moduleForm = {
            baseId: val.baseId,
            id: val.id,
            courseId: _this2.$route.query.courseId,
            baseType: _this2.baseType,
            cname: val.cname,
            ename: val.ename,
            content: val.content,
            contextType: val.contextType,
            //需要让后台传给我
            fileList: val.files,
            actList: _this2.$common.deepClone(val.activitys),
            description: val.description,
            rank: val.rank,
            state: val.state
          };
          //设置弹框组件里面的数据
          _this2.$refs.addObject.setData("moduleForm", _this2.moduleForm);
        }, 30);
      } else if (type === "del") {
        this.centerDialogVisibleModule = true;
        this.delModuleId = val.id;
        this.delModuleBaseId = val.baseId;
      } else {}
    },
    //添加模块
    addModule: function addModule(row) {
      //显示添加模块的弹框
      this.drawerModuleState = "add";
      this.$refs.addObject.showAddModule();
      this.$refs.addObject.setModuleForm('baseId', row.id);
    },
    initDataFn: function initDataFn() {
      this.$emit('navigation', this.navData); //设置面包屑
      this.getAllFun();
      this.getAllTargetFun();
    },
    // =============活动选择dialog相关函数 start  =============
    // dialog取消函数
    hdCancel: function hdCancel() {
      this.chooseActivityTree = [];
      this.chooseTreeTotal = 0;
      this.activityTreeState = false;
    },
    // dialog确定函数
    hdConfirm: function hdConfirm() {
      // this.drawerData.activitys = this.chooseActivityTree
      this.activityTreeState = false;
    },
    // dialog左边树枝右击选中函数
    activityTreeClick: function activityTreeClick(e, data, n, t) {
      var tmpData = {
        id: data.id,
        name: data.name
      };
      for (var i = 0; i < this.chooseActivityTree.length; i++) {
        if (this.chooseActivityTree[i].id === tmpData.id) {
          this.$toast.error("已经加入选择列表");
          return;
        }
      }
      this.chooseActivityTree.push(tmpData);
      this.chooseTreeTotal = this.chooseActivityTree.length;
    },
    // dialog右边删除函数
    delChooseTree: function delChooseTree(data) {
      this.chooseActivityTree.splice(this.chooseActivityTree.indexOf(data), 1);
      this.chooseTreeTotal = this.chooseActivityTree.length;
    },
    // dialog左边初始化树函数
    initActivityTree: function initActivityTree(val) {
      var _this3 = this;
      this.service.axiosUtils({
        requestName: 'coursestructureGetAll',
        data: {
          courseId: val
        },
        sucFn: function sucFn(res) {
          _this3.activityTreeData = res.data;
          // 展开数组
          _this3.activityTreeData.forEach(function (item) {
            _this3.defaultExpandedArr.push(item.id);
          });
        }
      });
    },
    selectTargetCourse: function selectTargetCourse() {
      this.initActivityTree(this.formCourseData.courseId);
    },
    // =================== 初始化数据 start===========================
    getAllTargetFun: function getAllTargetFun() {
      var _this4 = this;
      this.service.axiosUtils({
        requestName: 'getTargetareaAllList',
        data: {
          courseId: this.localCourse.id
        },
        sucFn: function sucFn(res) {
          _this4.treeChangeList = res.data;
        }
      });
    },
    getAllKeyFun: function getAllKeyFun(num) {
      var _this5 = this;
      // getKeyExpList
      this.service.axiosUtils({
        requestName: 'getKeyExpList',
        data: {
          areaId: num,
          courseId: this.localCourse.id
        },
        sucFn: function sucFn(res) {
          _this5.keyChangeList = res.data;
        }
      });
    },
    //获取树状结构
    getAllFun: function getAllFun() {
      var _this6 = this;
      this.service.axiosUtils({
        requestName: 'getTargetareaChildList',
        data: {
          courseId: this.localCourse.id
        },
        sucFn: function sucFn(res) {
          _this6.originTreeData = res.data;
          // 有所有
          if (!_this6.$route.query.tree) {
            var allTree = [{
              id: 0,
              name: "所有",
              children: res.data
            }];
            _this6.treeData = allTree;
            _this6.currentTree = _this6.treeData[0];
            _this6.changeRouter(_this6.currentTree);
          } else {
            _this6.treeData = res.data;
            _this6.currentTree = JSON.parse(_this6.$route.query.tree);
            _this6.changeRouter();
          }
          _this6.nowCheckData = _this6.currentTree;
          _this6.$nextTick(function () {
            _this6.$refs.tree.setCurrentKey(_this6.currentTree.id);
          });
        }
      });
    },
    //给其他组件使用的设置当前的tree
    setCurrentTree: function setCurrentTree(value) {
      this.currentTree = value;
    },
    //路由改变执行的方法
    changeRouter: function changeRouter(json) {
      // if(this.$route.path !== '/objective/LearningTrajectory') return;
      if (!json) {
        this.currentTree = this.$route.query.tree ? JSON.parse(this.$route.query.tree) : {};
      }
      this.filter[1].value = this.$route.query.resName || "";
      this.filter[0].value = this.$route.query.state || "";
      this.filterJson = {
        resName: this.filter[1].value,
        state: this.filter[0].value
      };
      this.initIds();
      this.initData();
    },
    // 初始化目标领域及关键经验id函数
    initIds: function initIds() {
      if (!this.currentTree.id) {
        // 所有的情况
        this.nowAreaId = 0;
        this.nowAreaName = '';
        this.nowConceptId = 0;
        this.nowConceptName = '';
      } else {
        if (this.currentTree.children) {
          // 选中是：非关键经验情况，目标领域
          this.nowAreaId = this.currentTree.id;
          this.nowAreaName = this.currentTree.name;
          this.nowConceptId = 0;
          this.nowConceptName = '';
        } else {
          // 选中是：关键经验
          // 目标领域id及名称
          for (var i = 0; i < this.originTreeData.length; i++) {
            if (parseInt(this.currentTree.areaId) === parseInt(this.originTreeData[i].id)) {
              this.nowAreaId = this.originTreeData[i].id;
              this.nowAreaName = this.originTreeData[i].name;
              continue;
            }
          }
          // 关键经验id及名称
          this.nowConceptId = this.currentTree.id;
          this.nowConceptName = this.currentTree.name;
        }
      }
    },
    // 初始化表格数据
    initData: function initData() {
      var _this7 = this;
      var postData = {
        areaId: this.nowAreaId,
        conceptId: this.nowConceptId,
        pageSize: this.pageSize,
        pageIndex: this.currentPage,
        title: this.filter[1].value,
        state: this.filter[0].value,
        courseId: this.localCourse.id
      };
      if (this.filter[1].value == "") {
        delete postData.title;
      }
      this.service.axiosUtils({
        requestName: "getLearningpathList",
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this7.tableData = res.data.data;
            _this7.total = res.data.total;
          } else {
            _this7.$toast.error(res.message);
          }
        }
      });
    },
    initCourse: function initCourse() {
      var _this8 = this;
      //getObjectTypeCourseAll
      this.service.axiosUtils({
        requestName: "getObjectTypeCourseAll",
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this8.courseList = res.data;
          } else {
            _this8.$toast.error(res.message);
          }
        }
      });
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    //筛选功能
    search: function search(filterArr) {
      var _this9 = this;
      this.service.axiosUtils({
        requestName: "getLearningpathList",
        data: {
          title: filterArr[1].value,
          state: filterArr[0].value,
          areaId: this.nowAreaId,
          conceptId: this.nowConceptId,
          pageSize: this.pageSize,
          pageIndex: this.currentPage,
          courseId: this.localCourse.id
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this9.tableData = res.data.data;
            _this9.total = res.data.total;
          } else {
            _this9.$toast.error(res.message);
          }
        }
      });
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.initData();
    },
    //新建初始化数据
    cleardrawerData: function cleardrawerData() {
      this.drawerData = {
        areaId: '',
        conceptId: '',
        title: "",
        ename: "",
        /* performance:'',//典型表现
         case:'', //案例展示
         guide:'',
         activitys:[], //活动列表*/
        rank: "",
        state: 1
      };
      this.chooseActivityTree = [];
      if (this.$refs.formDrawer) this.$refs.formDrawer.resetFields();
    },
    selectTargetChanged: function selectTargetChanged(val) {
      this.drawerData.areaId = val;
      this.keyChangeList = [];
      this.drawerData.conceptId = '';
      this.getAllKeyFun(val);
    },
    // =================== 页面目标领域关键经验树 相关函数 start===========================
    //点击某一个节点
    handleNodeClick: function handleNodeClick(data) {
      this.nowCheckData = _objectSpread({}, data);
      this.currentTree = _objectSpread({}, data);
      // delete this.currentTree.children
      if (this.currentTree.children) this.currentTree.children = [];
      var queryData = _objectSpread({}, this.$route.query);
      queryData.tree = JSON.stringify(this.currentTree);
      this.$router.replace({
        path: this.$route.path,
        query: queryData
      });
      //设置面包屑
      this.$emit('navigation', this.navData);
    },
    //点击展开收起
    isCollapseFn: function isCollapseFn() {
      if (this.isCollapse) {
        this.isCollapse = false;
      } else {
        //展开
        this.isCollapse = true;
      }
    },
    //  =================== 添加/编辑教-学路径 抽屉 相关函数  ===================
    // 添加函数
    addFun: function addFun() {
      this.cleardrawerData();
      if (!this.currentTree.id || this.nowCheckData.children && this.nowCheckData.children.length >= 0) {
        this.$toast.error('当前选项不能添加教-学路径');
        return;
      } else {
        this.initIds();
        if (!this.drawerData.areaId || !this.drawerData.conceptId) {
          this.drawerData.areaId = this.nowAreaId;
          this.drawerData.conceptId = this.nowConceptId;
          this.getAllKeyFun(this.drawerData.areaId);
        }
        this.drawerState = "add";
        this.drawer = true;
      }
    },
    // table-中操作函数
    detail: function detail(type, val) {
      if (type === "edit") {
        // this.nowAreaId = val.area.id
        this.nowAreaName = val.area.areaName;
        // this.nowConceptId = val.concept.id
        this.nowConceptName = val.concept.conceptName;
        this.drawerData = {
          areaId: val.areaId,
          rank: val.rank,
          state: val.state,
          conceptId: val.conceptId,
          title: val.title,
          ename: val.ename
          /*  performance:val.performance,//典型表现
            case:val.case, //案例展示
            guide:val.guide,
            activitys:val.activitys, //活动列表*/
        };

        this.drawerData.id = val.id;
        this.keyChangeList = [];
        this.getAllKeyFun(val.areaId);
        this.drawerState = "edit";
        this.drawer = true;
      } else if (type === "del") {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else {}
    },
    confirmClick: function confirmClick(formName) {
      var _this10 = this;
      // console.log("confirm走了几遍",this.drawerData)
      var that = this;
      this.$refs[formName].validate().then(function (valid) {
        if (valid) {
          var url = "";
          if (that.drawerState === "edit") {
            //修改
            url = "editLearningpath";
          } else {
            //添加
            url = "addLearningpath";
          }

          /* for(let i=0;i<this.drawerData.activitys.length;i++){
             this.drawerData.activitys[i].rank = i+1
           }*/

          that.drawerData.courseId = _this10.localCourse.id;
          that.service.axiosUtils({
            requestName: url,
            data: that.drawerData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                if (that.drawerState === "add") {
                  that.currentPage = 1;
                }
                that.initData();
                that.cleardrawerData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      }).catch(function (e) {
        console.log(e);
        //页面滚动到没通过验证的字段
        _this10.$refs[formName].scrollToField(Object.keys(e)[0]);
      });
      /* this.$refs[formName].validate((valid) => {
         alert(valid)
         if (valid) {
           let url = "";
            if (that.drawerState === "edit") {
             //修改
             url = "editLearningpath";
           } else {
             //添加
             url = "addLearningpath";
           }
            for(let i=0;i<this.drawerData.activitys.length;i++){
             this.drawerData.activitys[i].rank = i+1
           }
            that.drawerData.courseId = this.localCourse.id
            that.service.axiosUtils({
             requestName: url,
             data: that.drawerData,
             sucFn: (res) => {
               if (res.status === 1) {
                 that.$toast.success(res.message);
                 that.drawer = false;
                 if (that.drawerState === "add") {
                   that.currentPage = 1;
                 }
                 that.initData();
                 that.cleardrawerData();
                } else {
                 that.$toast.error(res.message);
               }
             },
           });
         }
       });*/
    },
    cancelClick: function cancelClick() {
      this.drawer = false;
      this.drawerState = "";
    },
    // 显示弹框函数
    showDialog: function showDialog(type, model) {
      //去掉指南、进程定义、典型表现和活动列表
      /*      if(type === 'performance' || type === 'case' || type === 'guide'){
              this.nowArticleData={
                'content':model
              }
              this.itemName = type
              this.isEditorShow = true
            }else if(type === 'activity'){
      
              this.activityTreeState = true
              this.initCourse();
              this.initActivityTree(this.localCourse.id)
              this.formCourseData.courseId = this.localCourse.id;
      
      
              this.chooseActivityTree = []
              this.chooseActivityTree = this.chooseActivityTree.concat(this.drawerData.activitys)
              this.chooseTreeTotal = this.chooseActivityTree.length
            }else{}*/
    },
    //====================== table 操作函数 =======================
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this11 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "changeLearningpathState",
        data: {
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this11.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this11.$toast.error(res.message);
          }
        }
      });
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this12 = this;
      //删除当前的资源类型
      this.service.axiosUtils({
        requestName: "deleteLearningpath",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this12.$toast.success(res.message);
            _this12.delId = "";
            _this12.centerDialogVisible = false;
            _this12.initData();
          } else {
            _this12.$toast.error(res.message);
          }
        }
      });
    }
  }
};